import { useParams } from "react-router";
import * as Styled from "./styles";

const CareInstructions = () => {
  const params = useParams();
  return (
    <Styled.CareInstructionsPage>
      {params.productName === "bread-bow-knife" && (
        <>
          <h1>We hope you enjoy your new Bread Bow Knife!</h1>
          <h3>
            Your safety is our priority. Please follow these guidelines to enjoy
            the best experience with your Bread Bow Knife.
          </h3>
          <ul>
            <li>
              <strong>Handle with Care:</strong> The blade is extremely sharp.
              Always handle it with caution to avoid accidental cuts.
            </li>
            <li>
              <strong>Safe Cutting Technique:</strong> Always cut on a stable
              surface and away from your body. Keep your fingers clear of the
              blade's path.
            </li>
            <li>
              <strong>Keep Away from Children: </strong>Ensure that the knife is
              kept out of reach of children to prevent injury.
            </li>
            <li>
              <strong>Blade Wobbling: </strong>If you notice that the blade
              starts wobbling, stop using the knife immediately. Contact
              bohananwoodcraft@gmail.com for assistance. A wobbling blade can be
              dangerous and should be fixed or replaced to prevent accidents.
            </li>
            <li>
              <strong>Regular Maintenance: </strong>We have finished the wood
              handle with food-safe mineral oil. Apply more mineral oil as
              needed to keep the wood conditioned.
            </li>
            <li>
              <strong>Cleaning Instructions: </strong>Hand wash only, Do not
              soak
            </li>
          </ul>
          <h2>Tips on how to put on/off blade guard</h2>
          <video width="300" height="500" controls>
            <source src="/assets/videos/video_1.mp4" type="video/mp4" />
          </video>
          <p>
            Sometimes the blade guard might be too tight, you can cut a corner
            using scissors to create more space for blade to slide in
          </p>
          <video width="300" height="500" controls>
            <source src="/assets/videos/video_2.mp4" type="video/mp4" />
          </video>
        </>
      )}
      <a
        className="product__store-link"
        href="https://bohananwoodcraft.etsy.com"
        target="_blank"
        rel="noreferrer"
      >
        Explore our products
      </a>
    </Styled.CareInstructionsPage>
  );
};

export default CareInstructions;
